@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: 'Raleway', sans-serif;
}

.text-primary {
  color: #0B4380 !important;
}

.bg-primary {
  background-color: #0B4380 !important;
}


.text-secondary {
  color: #909C9E !important;
}

.text-info {
  color: #3d6590 !important;
}


.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.hero-section {
  background: url('./Assets/bg-img.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  min-height: 80vh;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
}

.primary-btn {
  background: #0C77CA;
}

.primary-btn:hover {
  background: hsl(206, 89%, 42%, .75);
  transition: all .3s ease;
}

.footer-title-border {
  width: 100%;
  background-color: #214087;
  height: 3px;
  position: relative;
  z-index: 10;
}

.footer-title-border::after {
  content: '';
  position: absolute;
  background: #214087;
  ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50%;
  height: 8px;
  z-index: 10;
}

.footer-title {
  color: #c5c5c5;
}

.footer-link {
  color: #909C9E;
  transition: all .3s ease;


}

.footer-link i {
  color: #58523F;

}

.footer-link:hover {
  filter: brightness(.5);
  transition: all .3s ease;
}

.login-btn {
  background: #04C35C;

}

.login-btn:hover {
  background: hsla(148, 96%, 39%, 0.837);
  transition: all .3s ease;
}

.btn-facebook {
  background: #1877F2 !important;

}

.btn-google {
  background: #2d3748 !important;
}

.btn-linkedin {
  background: #2d3748 !important;
}

.btn-linkedin i {
  background: #007AB9 !important;
  padding: 6px 7px;
  border-radius: 50px;
  margin-right: 5px;
}


@media screen and (max-width:300px) {
  header .top-bar {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    height: 45px;
  }
}

.-translate-y-full {
  transform: translateY(-100%);
}

/* .nav-link {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #aaa;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 10px;
}
.nav-link,
.nav-link:after,
.nav-link:before {
  transition: all .5s;
}
.nav-link:hover {
  color: #555;
} */

.nav-link {
  position: relative;
  z-index: 1;
  padding: .25rem .65rem;
}

.nav-link:hover {
  color: #fff;
  transition: all .5s ease;

}

.nav-link:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: '.';
  color: transparent;
  background: #0B4380;
  visibility: none;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
}

.nav-link:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
  transition: all .5s ease;
}

.nav-icons:hover~.nav-link {
  filter: brightness(.5);
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* @media screen and (min-width:1280px) {

  .container {
    width: 1200px;
    margin: auto;
  }
} */
.filter__modal {
  transform: translateY(92%);
}

/* .css-1f2kvjf-MuiFormControlLabel-root {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin: 0 !important;
} */

.css-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: 'Raleway', sans-serif !important;
  font-size: smaller !important;
  font-weight: 500 !important;
}

.css-1nrlq1o-MuiFormControl-root {
  width: 100% !important;
}

.multi-select-dropdown {
  min-height: 2rem;
  max-height: 10rem;
  position: absolute;
  z-index: 40;
  background-color: #fff;
  border: 1px solid #e6edef;
  width: 100%;
  padding: 7px;
  overflow-y: scroll;
}


body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f1f5f9;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #f1f5f9;
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 95vw !important;
    padding: 0;
    left: 0 !important;
    margin: auto !important;
    right: 0 !important;
    margin-bottom: 0.45rem !important;
  }
}

menu,
ol,
ul {
  list-style-position: outside !important;
  list-style-type: circle !important;
  margin: 0;
  padding: 0;
}

.custom___input {
  box-sizing: border-box;
  /* padding: 4px 6px; */
  width: 0px;
  min-width: 30px;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

/* .alliences___ {
  width: 100%;
  display: flex;
  animation: moveSlideshow 12s linear infinite;

}

@keyframes moveSlideshow {
  100% { 
    transform: translateX(-66.6666%);  
  }
} */

.carousel-prev,
.carousel-next {
  opacity: 0;
  font-size: 0;
  line-height: 0;
  width: 40px;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: end !important;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 4;
  background: none;
}

.carousel-prev {
  right: 40px !important;
  left: unset !important;
}

.carousel-next:before {
  background-image: url(https://cdn-icons-png.flaticon.com/512/545/545682.png) !important;
  position: absolute;
  bottom: 0;
}

.carousel-prev:before {
  background-image: url(https://cdn-icons-png.flaticon.com/512/545/545680.png) !important;
  position: absolute;
  bottom: 0;
}

.aboute___img {
  background-image: url('./Assets/pedro-lastra-Nyvq2juw4_o-unsplash.jpg');
  mask-image: url('./Assets/Vectors/Mask\ group.png');
  mask-position: right;
  mask-repeat: no-repeat;
  mask-size: cover;
  background-size: cover;
  -webkit-mask-position-x: 100px;
  height: 92vh;
}


.img___entry___animation {
  /* height: 0%; */
  /* opacity: 0; */
  animation-name: projectImgAnimation;
  /* animation-delay: 1s; */
  animation-duration: 2s;
  animation-timing-function: ease-out;
}

@keyframes projectImgAnimation {
  0% {
    /* height: 0%; */
    opacity: 0;
  }

  100% {
    /* height: 100%; */
    opacity: 1;
  }
}


.react-tel-input .form-control {
  font-size: unset !important;
  background: #f1f5f9 !important;
  border: unset !important;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
  padding: 8.5px 14px 8.5px 60px !important;
  transition: box-shadow ease .25s,border-color ease .25s;
  color: unset !important;
}

.react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 0px;
  padding: 3px 8px 5px;
  outline: none;
  width: 96% !important;
}