.box {
  /* width: 100%; */
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.box .title {
  /* width: max-content; */
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
.box .title .block {
  width: 0%;
  height: inherit;
  /* background: #535fa9; */
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}
.box .title h1 {
  /* font-family: 'Poppins'; */
  /* color: #000; */
  /* font-size: 32px; */
  -webkit-animation: mainFadeIn 2s forwards;
  -o-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
}
/* .box .title h1 span {
  width: 0px;
  height: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #535fa9;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  margin-left: 5px;
  position: absolute;
  bottom: 13px;
  right: -12px;
} */
.box .role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 0;
}
.box .role .block {
  width: 0%;
  height: inherit;
  /* background: #ddd6fe; */
  position: absolute;
  animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  display: flex;
}
.box .role p {
  animation: secFadeIn 2s forwards;
  animation-delay: 3.2s;
  opacity: 0;
  color: #000;
}
@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
 }
  50% {
    width: 100%;
    left: 0;
 }
  100% {
    width: 0;
    left: 100%;
 }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
 }
  50% {
    width: 100%;
    left: 0;
 }
  100% {
    width: 0;
    left: 100%;
 }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
 }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
 }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
 }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
 }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
 }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 0.5;
 }
}
@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
 }
  100% {
    opacity: 1;
    bottom: 0px;
 }
}
@keyframes icon {
  0% {
    opacity: 0;
    transform: scale(0);
 }
  50% {
    opacity: 1;
    transform: scale(1.3) rotate(-2deg);
 }
  100% {
    opacity: 1;
    bottom: 0px;
 }
}
